<template>
  <div
    class="dict"
    v-loading.fullscreen.lock="fullPageLoading"
  >
    <el-page-header
      style="margin-bottom: 1.04167vw"
      @back="goBack"
      :content="'运营列表/办理'"
    >
      <template slot="title">
        <i class="el-icon-arrow-left"></i>返回
      </template>
    </el-page-header>

    <el-form
      ref="ruleForm"
      style="width: 80%"
      :model="form"
      :rules="formRules"
      :label-width="labelWidth"
    >
      <el-form-item
        label="完成情况："
        prop="comment"
      >
        <el-input
          type="textarea"
          placeholder="请输入完成情况"
          :maxlength="500"
          v-model="form.comment"
          :rows="4"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="附件："
        prop="operateAttachmentList"
      >
        <el-upload
          class="upload-demo"
          :multiple="multiple"
          :before-upload="checkFileType"
          action="https://jsonplaceholder.typicode.com/posts/"
          :limit="limit"
          :on-exceed="handleExceed"
          :http-request="onSuccess"
          :before-remove="handleDelFile"
          :file-list="form.operateAttachmentList"
        >
          <el-button
            size="small"
            type="primary"
          >上传附件</el-button>
        </el-upload>
        <span
          class="supportType"
          v-if="isAttachmentLimit == 1 && attachmentShowName"
        >
          <!-- 支持<span v-for="(item,index) in attachmentTypeName" :key="index">{{item}}，</span>类型格式文件 -->
          {{ attachmentShowName }}
        </span>
        <span
          class="supportType"
          v-else
        >
          <!-- 支持<span v-for="(item,index) in attachmentTypeName" :key="index">{{item}}，</span>类型格式文件 -->
          上传文件限制为：最多上传1个文件，支持上传文件类型：照片、视频、Word、Excel、PPT、PDF；
        </span>
      </el-form-item>
      <el-form-item label="">
        <el-button @click="goBack">取消</el-button>
        <el-button
          type="primary"
          :loading="loading"
          :disabled="loading"
          @click="submitBtn"
        >确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  data () {
    return {
      fullPageLoading: false,
      loading: false,
      form: {
        comment: '',
        operateAttachmentList: [],
      },
      attachmentShowName: "",
      attachmentTypeValue: [],
      attachmentTypeName: [],
      fileList: [],
      formRules: {
        comment: [],
        operateAttachmentList: []
      },
      multiple: true,
      limit: 1,
      isAttachmentLimit: 0,

      labelWidth: '120px',
    }
  },
  computed: {
    ...mapState({
      fileTypeList: (state) => state.common.fileTypeList,
    })
  },
  watch: {
    fileTypeList: {
      immediate: true,
      handler (val) {
        if (val) {
          this.initRole()
        }
      },
      deep: true
    }
  },
  created () {
    if (this.fileTypeList) {
      this.initRole()
    }
    // this.initRole()
  },
  mounted () {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if (window.innerWidth < 1300) {
      this.labelWidth = '80px'
    } else {
      this.labelWidth = (120 / 1920 * window.innerWidth) + 'px'
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    isRequired () { },
    submitBtn () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$api.taskExecute({
            taskDetailId: this.$route.query.id,
            comment: this.form.comment,
            operateAttachmentList: this.form.operateAttachmentList
          }).then(res => {
            if (res.data.code == '0') {
              this.$message.success('保存成功')
              this.goBack()
            } else if (res.data.code == 3) {
              this.goBack()
              this.$message.error(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        }
      })

    },
    // 获取验证规则
    initRole () {
      this.$api.getCompleteSet(this.$route.query.id).then(res => {
        if (res.data.code == '0') {
          console.log(res.data.data)
          if (res.data.data.isMust == 1) {
            this.formRules.comment = [
              { required: true, message: "请输入完成情况", trigger: "blur" },
            ]
          }
          this.isAttachmentLimit = res.data.data.isAttachmentLimit
          if (res.data.data.isAttachmentLimit == 1) {
            this.formRules.operateAttachmentList = [
              { required: true, message: "请上传附件", trigger: "blur" },
            ]

            let uploadTipString = []
            let attachTypeList = res.data.data.attachmentType.split(",").filter((info) => { return Number(info) > 5 })
            let isFileType = ""
            let isVideoType = ""
            if (res.data.data.attachmentType.indexOf("2") != -1) {
              isFileType = '2'
            } else if (res.data.data.attachmentType.indexOf("1") != -1 && res.data.data.attachmentType.indexOf("2") == -1) {
              isFileType = '1'
            }
            if (res.data.data.attachmentType.indexOf("5") != -1) {
              isVideoType = '5'
            } else if (res.data.data.attachmentType.indexOf("4") != -1 && res.data.data.attachmentType.indexOf("5") == -1) {
              isVideoType = '4'
            } else if (res.data.data.attachmentType.indexOf("3") != -1 && res.data.data.attachmentType.indexOf("4") == -1 && res.data.data.attachmentType.indexOf("5") == -1) {
              isVideoType = '3'
            }
            if (isFileType) {
              if (isFileType == '2') {
                this.multiple = true
                this.limit = 9
              } else {
                this.multiple = false
                this.limit = 1
              }
              attachTypeList.push(isFileType)
            }
            if (isVideoType) {
              attachTypeList.push(isVideoType)
            }
            if (attachTypeList.length > 0) {
              attachTypeList.map((attachTypeInfo) => {
                let attachInfo = this.fileTypeList.find((file) => { return file.value == attachTypeInfo })
                console.log(attachInfo)
                if (attachInfo) {
                  uploadTipString.push(attachInfo.name)
                }
              })
              this.attachmentShowName = '上传文件限制为：' + uploadTipString.join(",") + "。"
              this.attachmentTypeValue = attachTypeList
            } else {
              this.attachmentShowName = ''
              this.attachmentTypeValue = []
            }

            // if(res.data.data.attachment && res.data.data.attachmentType.indexOf(2)>=0){
            //     this.multiple = true
            //     this.limit = 9
            // }
            // if(res.data.data.attachmentType){
            //     this.attachmentTypeValue = res.data.data.attachmentType.split(',')
            // }
            // 匹配图片
            if (res.data.data.attachmentType && res.data.data.attachmentType.indexOf(1) >= 0 || res.data.data.attachmentType.indexOf(2) >= 0) {
              this.attachmentTypeName.push('gif', 'png', 'jpeg', 'jpg')
            }
            // 匹配视频
            if (res.data.data.attachmentType && res.data.data.attachmentType.indexOf(3) >= 0 || res.data.data.attachmentType.indexOf(4) >= 0 || res.data.data.attachmentType.indexOf(5) >= 0) {
              this.attachmentTypeName.push('mp4', 'rmvb', 'mov', 'flv', 'avi', 'mpg')
            }
            // 匹配ppt
            if (res.data.data.attachmentType && res.data.data.attachmentType.indexOf(6) >= 0) {
              this.attachmentTypeName.push('ppt', 'pptx')
            }
            // 匹配word
            if (res.data.data.attachmentType && res.data.data.attachmentType.indexOf(7) >= 0) {
              this.attachmentTypeName.push('doc', 'docx')
            }
            // 匹配excel
            if (res.data.data.attachmentType && res.data.data.attachmentType.indexOf(8) >= 0) {
              this.attachmentTypeName.push('xls', 'xlsx')
            }
            // 匹配pdf
            if (res.data.data.attachmentType && res.data.data.attachmentType.indexOf(9) >= 0) {
              this.attachmentTypeName.push('pdf')
            }
          }
          // console.log(this.attachmentTypeName)
        } else {
          this.$message.error(res.data.msg)
        }
      })

    },
    async checkFileType (file, fileList) {
      return new Promise(async (resolve, reject) => {
        if (this.attachmentShowName) {
          let limitImg = true
          let limitVideo = true
          let limitFile = true
          let limitPPTType = true
          let limitWORDType = true
          let limitEXCELType = true
          let limitPDFType = true
          let imgSingle = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '1' })
          let imgMultiple = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '2' })
          let videoOne = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '3' })
          let videoThree = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '4' })
          let videoFifteen = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '5' })
          let isPPT = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '6' })
          let isWORD = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '7' })
          let isEXCEL = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '8' })
          let isPDF = this.attachmentTypeValue.findIndex((fileTypeInfo) => { return fileTypeInfo == '9' })
          let type = file.name.substr(file.name.lastIndexOf(".") + 1, file.name.length).toLowerCase()
          let isimg = ['png', 'jpg', 'jpeg', 'gif'].findIndex((imgInfo) => { return imgInfo == type })
          let isvideo = ['mp4', 'mov', 'rmvb', 'flv', 'avi', 'mpg'].findIndex((videoInfo) => { return videoInfo == type })
          let isPPTType = isPPT != -1 ? ['ppt', 'pptx'].findIndex((PPTInfo) => { return PPTInfo == type }) : -1
          let isWORDType = isWORD != -1 ? ['doc', 'docx'].findIndex((WORDInfo) => { return WORDInfo == type }) : -1
          let isEXCELType = isEXCEL != -1 ? ['xls', 'xlsx'].findIndex((EXCELInfo) => { return EXCELInfo == type }) : -1
          let isPDFType = isPDF != -1 ? ['pdf'].findIndex((PDFInfo) => { return PDFInfo == type }) : -1
          let duration;
          if (isvideo != -1) {
            duration = await this.getVideoTime(file);
          }
          if (imgSingle != -1) {
            limitImg = this.form.operateAttachmentList && this.form.operateAttachmentList.length == 0 || !this.form.operateAttachmentList
          }
          if (imgMultiple != -1) {
            limitImg = this.form.operateAttachmentList && this.form.operateAttachmentList.length < 9 || !this.form.operateAttachmentList
          }
          if (videoOne != -1 && videoThree == -1 && videoFifteen == -1) {
            limitVideo = duration && duration <= 60
          }
          if (videoThree != -1 && videoFifteen == -1) {
            limitVideo = duration && duration <= (3 * 60)
          }
          if (videoFifteen != -1) {
            limitVideo = duration && duration <= (15 * 60)
          }

          limitPPTType = isPPT != -1 && isPPTType != -1 || isPPT == -1 ? true : false
          limitWORDType = isWORD != -1 && isWORDType != -1 || isWORD == -1 ? true : false
          limitEXCELType = isEXCEL != -1 && isEXCELType != -1 || isEXCEL == -1 ? true : false
          limitPDFType = isPDF != -1 && isPDFType != -1 || isPDF == -1 ? true : false
          if (limitPPTType && limitWORDType && limitEXCELType && limitPDFType) {
            limitFile = true
          } else {
            limitFile = false
          }

          if (isimg != -1) {
            if (imgSingle != -1) {
              if (!limitImg) {
                this.$message.error('上传图片只能上传一张!');
              }
              if (limitImg && (isimg != -1)) {
                this.fullPageLoading = true
                resolve(true)
              } else {
                reject(false)
              }
            } else if (imgMultiple != -1) {
              if (!limitImg) {
                this.$message.error('上传图片只能上传九张!');
              }
              if (limitImg && (isimg != -1)) {
                this.fullPageLoading = true
                resolve(true)
              } else {
                reject(false)
              }
            } else {
              this.$message.error('上传失败，请上传 ' + ((videoOne != -1 || videoThree != -1 || videoFifteen != -1) ? 'mp4, mov, rmvb, flv, avi, mpg, ' : '') + (isPPT != -1 ? 'ppt, pptx, ' : '') + (isWORD != -1 ? 'doc, docx, ' : '') + (isEXCEL != -1 ? 'xls, xlsx, ' : '') + (isPDF != -1 ? 'pdf ' : '') + '格式文件');
              reject(false)
            }
          } else if (isvideo != -1) {
            if (videoOne != -1 && videoThree == -1 && videoFifteen == -1) {
              if (!limitVideo) {
                this.$message.error('上传视频时长只能是一分钟以内!');
              }
              // if (!isvideo) {
              //   this.$message.error('上传视频只能是 mp4 格式!');
              // }
              if (limitVideo && isvideo != -1) {
                this.fullPageLoading = true
                resolve(true)
              } else {
                reject(false)
              }
            } else if (videoThree != -1 && videoFifteen == -1) {
              if (!limitVideo) {
                this.$message.error('上传视频时长只能是三分钟以内!');
              }
              // if (!isvideo) {
              //   this.$message.error('上传视频只能是 mp4 格式!');
              // }
              if (limitVideo && isvideo != -1) {
                this.fullPageLoading = true
                resolve(true)
              } else {
                reject(false)
              }
            } else if (videoFifteen != -1) {
              if (!limitVideo) {
                this.$message.error('上传视频时长只能是十五分钟以内!');
              }
              // if (!isvideo) {
              //   this.$message.error('上传视频只能是 mp4 格式!');
              // }
              if (limitVideo && isvideo != -1) {
                this.fullPageLoading = true
                resolve(true)
              } else {
                reject(false)
              }
            } else {
              this.$message.error('上传失败，请上传 ' + ((imgSingle != -1 || imgMultiple != -1) ? 'png, jpg, jpeg, gif, ' : '') + (isPPT != -1 ? 'ppt, pptx, ' : '') + (isWORD != -1 ? 'doc, docx, ' : '') + (isEXCEL != -1 ? 'xls, xlsx, ' : '') + (isPDF != -1 ? 'pdf ' : '') + '格式文件');
              reject(false)
            }
          } else if (isPPTType != -1 || isWORDType != -1 || isEXCELType != -1 || isPDFType != -1) {
            if (limitPPTType || limitWORDType || limitEXCELType || limitPDFType) {
              this.fullPageLoading = true
              resolve(true)
            } else if (isPPTType != -1 || isWORDType != -1 || isEXCELType != -1 || isPDFType != -1) {
              this.$message.error('上传失败，请上传 ' + (isPPT != -1 ? 'ppt, pptx, ' : '') + (isWORD != -1 ? 'doc, docx, ' : '') + (isEXCEL != -1 ? 'xls, xlsx, ' : '') + (isPDF != -1 ? 'pdf ' : '') + ' 格式文件');
              reject(false)
            } else {
              this.$message.error('上传失败，请上传 ' + ((imgSingle != -1 || imgMultiple != -1) ? 'png, jpg, jpeg, gif, ' : '') + ((videoOne != -1 || videoThree != -1 || videoFifteen != -1) ? 'mp4, mov, rmvb, flv, avi, mpg ' : '') + '格式文件');
              reject(false)
            }
          } else {
            this.$message.error('上传失败，请上传 ' + ((imgSingle != -1 || imgMultiple != -1) ? 'png, jpg, jpeg, gif, ' : '') + ((videoOne != -1 || videoThree != -1 || videoFifteen != -1) ? 'mp4, mov, rmvb, flv, avi, mpg, ' : '') + (isPPT != -1 ? 'ppt, pptx, ' : '') + (isWORD != -1 ? 'doc, docx, ' : '') + (isEXCEL != -1 ? 'xls, xlsx, ' : '') + (isPDF != -1 ? 'pdf ' : '') + '格式文件');
            reject(false)
          }
        } else {
          this.fullPageLoading = true
          resolve(true)
        }
      })
      // const fileName = file.name;
      // const fileType = fileName.substring(fileName.lastIndexOf('.')+1);
      // let attType = this.attachmentTypeName.join(',')
      // // console.log(fileType,attType)
      // if(attType && attType.indexOf(fileType) == -1){
      //     this.$message.error('上传类型与要求不符')
      //     return false
      // }
    },
    //获取视频时长
    getVideoTime (file) {
      return new Promise((resolve, reject) => {
        //获取视频或者音频时长
        let fileurl = URL.createObjectURL(file);
        //经测试，发现audio也可获取视频的时长
        let audioElement = new Audio(fileurl);
        audioElement.addEventListener("loadedmetadata", function (_event) {
          resolve(audioElement.duration)
        });
      })
    },
    handleExceed (files, fileList) {
      if (files.length + fileList.length > this.limit) {
        this.$message.error('最大上传数量超过限制数量')
        return
      }
    },
    onSuccess (file) {
      var fileData = new FormData();
      // console.log(file)
      fileData.append('file', file.file);
      this.$api.uploadFile(fileData).then(res => {
        this.fullPageLoading = false
        if (res.data.code == '0') {
          // console.log(res.data,'wenjian')
          let type = file.file.name.substr(file.file.name.lastIndexOf(".") + 1, file.file.name.length).toLowerCase()
          this.form.operateAttachmentList.push({
            path: res.data.data.fileUrl,
            pdfPath: res.data.data.pdfUrl,
            type: type,
            name: file.file.name,
            uid: file.file.uid
          })
          this.$message.success("上传成功");
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => { })
    },
    handleDelFile (files, fileList) {
      console.log(files)
      let index = this.form.operateAttachmentList.findIndex((info) => { return info.path == files.path })
      if (index != -1) {
        this.form.operateAttachmentList.splice(index, 1)
      }
    },
  },
  beforeDestroy () {
    window.removeEventListener("resize", (e) => {
      if (e.target.innerWidth < 1300) {
        this.labelWidth = '80px'
      } else {
        this.labelWidth = (120 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";
.dict {
  background-color: #ffffff;
  height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  .tableList {
    height: calc(100% - 100px);
  }
}
.supportType {
  display: inline-block;
  // margin-left: 38px;
  color: #c6000b;
  white-space: nowrap;
}
</style>